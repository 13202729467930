/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  background: linear-gradient(101.04deg, $color-light-blue 0.25%, $color-dark-green 97.78%);
  color: $color-white;
  padding: 1em;

  @include bp-large {
    padding: 2em 2em 1em 2em;
  }

  @include bp-full {
    padding: 3em 3em 1em 3em;
  }
}
