
.c-product__meta {
  @include clearfix();
  margin-bottom: 3em;
}

.c-product__category {
  color: $color-alt;
  float: left;
  margin: 0;
  width: 50%;
}

.c-product__category-link {
  display: block;
  font-weight: bold;
  margin-top: 0.5em;
}

.c-product__logo {
  float: right;
  margin-top: 1em;
  text-align: right;
}
