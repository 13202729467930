
.c-box {
  background: $color-white;
  box-shadow: $global-box-shadow;
  padding: em(30) em(20);
  margin-bottom: em(40);

  @include bp-small {
    padding: em(30) em(40);
    margin-bottom: em(40);
  }

  @include bp-medium {
    padding: em(35) em(50);
    margin-bottom: em(40);
  }

  @include bp-large {
    padding: em(45) em(60);
    margin-bottom: em(50);
  }

  @include bp-full {
    padding: em(55) em(70);
    margin-bottom: em(60);
  }
}

.c-box--narrow {
  margin: 0 auto 3em auto;
  max-width: 1080px;

  p:last-child {
    margin: 0;
  }
}

.c-box--small-bottom-padding {
  padding-bottom: em(10);
}

.c-box--no-bottom-padding {
  padding-bottom: 0;
}
