
.c-form-box {
  border-radius: $global-radius;
  border: 1px solid $color-border;
  box-shadow: 0 2px 3px rgba(153, 153, 153, 0.1);
  margin-top: 1em;
  padding: em(24);

  @include bp-medium {
    margin-top: 2em;
    padding: em(34);
  }

  @include bp-large {
    margin-top: 3em;
    padding: em(44);
  }
}

.c-form-box__title {
  font-size: rem(24);
  margin: 0 auto 1em auto;
  max-width: 500px;
  text-align: center;
}

.c-form-box__desc {
  color: $color-alt;
  margin: 0 auto;
  max-width: 500px;
  text-align: center;
}

.c-form__note {
  color: $color-faded-text;
  font-size: rem(14);
  font-style: italic;
  margin: 0.5em 0 3em 0;
  text-align: right;
}
