// Pagination

.c-pagination {
  @include clearfix();
}

.c-pagination__link {
  display: block;
  font-weight: bold;
  text-decoration: none;
}

.c-pagination__icon {
  display: inline-block;
  fill: $color-link;
  height: rem(8);
  vertical-align: middle;
  width: rem(13);
  stroke: $color-link;

  &:hover {
    stroke: $color-hover;
  }
}

.c-pagination__link--newer {
  float: right;

  .c-pagination__icon {
    margin-left: 0.25em;
    transform: rotate(-90deg);
  }
}

.c-pagination__link--older {
  float: left;

  .c-pagination__icon {
    margin-right: 0.25em;
    transform: rotate(90deg);
  }
}
