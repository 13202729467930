.c-form {
  max-width: 500px;
  margin: 0 auto;
}

.c-form__error {
  color: $color-error;
  font-size: rem(16);
  font-style: italic;
  margin: 1em 0;
  text-align: center;
}

.c-form__label {
  color: $color-emp-text;
  display: block;
  font-weight: bold;
  margin: 1em 0 0.25em 0.5em;
}

.c-form__input {
  border: 1px solid $color-border;
  border-radius: $global-radius;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.06);
  font-size: rem(16);
  line-height: 1.5;
  padding: 0.5em 1em;
  width: 100%;
}

.c-form__s {
  display: none;
}
