
@keyframes c-heading-full-line {
  to {
    width: 100%;
  }
}

@keyframes c-heading-opacity {
  to {
    opacity: 1;
  }
}

@keyframes c-heading-fade-in-up {
  from {
    opacity: 0;
    transform: translate3d(0, 50%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.c-heading {
  color: $color-emp-heading;
  display: inline-block;
  font-size: rem(31);
  font-style: italic;
  margin: 0 0 rem(40) 0;
  opacity: 0;
  will-change: transform;

  em {
    font-weight: normal;
    color: $color-heading;
  }

  &:after {
    background: $color-border-darker;
    content: '';
    display: block;
    height: 5px;
    margin-top: 0.15em;
    width: 0;
  }

  &.is-visible {
    animation: c-heading-fade-in-up 500ms ease-in-out forwards;
  }

  &.is-visible:after {
    animation: c-heading-full-line 400ms ease-in-out forwards;
    animation-delay: 200ms;
  }
}
