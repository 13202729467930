
.c-logo__img {
  height: em(57);
  position: absolute;
  width: em(150);
  top: em(51);
  left: 2em;

  @include bp-medium {
    top: em(51);
    left: em(20);
  }

  @include bp-large {
    left: em(30);
  }

  @include bp-xlarge {
    left: em(40);
  }

  @include bp-full {
    left: em(50);
  }
}
