
.c-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.c-gallery__item {
  box-shadow: $global-box-shadow;
  display: block;
  margin-bottom: 2em;
  width: 31%;
  transition: $global-transition;

  &:hover {
    box-shadow: $global-box-shadow-hover;
  }
}

.c-gallery__item--empty {
  box-shadow: none;

  &:hover {
    box-shadow: none;
  }
}

.c-gallery__image {
  display: block;
}
