
.c-product-thumb {
  background: $color-white;
  box-shadow: $global-box-shadow;
  margin-bottom: em(50);
  transition: $global-transition;
  will-change: transform;

  &:hover {
    box-shadow: $global-box-shadow-hover;
    transform: scale(1.03);
  }
}

.c-product-thumb__link {
  display: block;
  text-decoration: none;
}

.c-product-thumb__title-wrapper {
  display: flex;
  height: rem(90);
  padding: 0 1em;
}

.c-product-thumb__title {
  font-size: rem(21);
  text-align: center;
  margin: auto;
}
