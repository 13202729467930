// Search

.c-search {
  display: flex;

  @include bp-medium {
    position: absolute;
    right: em(120);
    top: em(62);
  }

  @include bp-large {
    right: em(130);
  }

  @include bp-xlarge {
    right: em(140);
  }

  @include bp-full {
    right: em(150);
  }
}

.c-search__input {
  background: $color-white;
  border-radius: 3px 0 0 3px;
  border-left: 1px solid $color-border;
  border-top: 1px solid $color-border;
  border-bottom: 1px solid $color-border;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.06);
  display: block;
  font-size: rem(14);
  height: rem(36);
  padding: 0 0.5em;
  width: em(245);

  @include bp-large {
    right: em(255);
  }

  @include bp-xlarge {
    right: em(265);
  }

  @include bp-full {
    right: em(275);
  }

  &::placeholder {
    color: $color-placeholder;
  }
}

.c-search__icon {
  display: block;
  width: rem(18);
  height: rem(18);
  fill: $color-white;
}

.c-search__btn {
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.06);
  border-radius: 0 3px 3px 0;
  font-size: rem(16);
  font-weight: bold;
  height: rem(36);
  padding: rem(10) rem(12);

  input {
    display: none;
  }
}

