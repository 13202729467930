/* Newsletter */

.c-newsletter {
  margin-bottom: 2em;
}

.c-newsletter__title {
  color: $color-white;
  font-size: rem(16);
  margin-bottom: 0.5em;
}

.c-newsletter__desc {
  font-size: rem(14);
  margin-bottom: 0.5em;
}

.c-newsletter__form {
  display: flex;
  margin-top: 1em;
}

.c-newsletter__input {
  background: $color-white;
  border-radius: 3px 0 0 3px;
  border-left: 1px solid $color-border;
  border-top: 1px solid $color-border;
  border-bottom: 1px solid $color-border;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.06);
  display: block;
  font-size: rem(14);
  height: 36px;
  padding: 0 0.5em;

  &::placeholder {
    color: $color-placeholder;
  }
}

.c-newsletter__btn {
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.06);
  border-radius: 0 3px 3px 0;
  font-size: rem(16);
  font-weight: bold;
}
