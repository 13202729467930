/* pt-sans-regular - latin */
@font-face {
  font-family: 'PT Sans';
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: local('PT Sans'), local('PTSans-Regular'),
    url('../assets/fonts/pt-sans-v8-latin-regular.woff2') format('woff2'),
    url('../assets/fonts/pt-sans-v8-latin-regular.woff') format('woff');
}

/* pt-sans-italic - latin */
@font-face {
  font-family: 'PT Sans';
  font-display: swap;
  font-style: italic;
  font-weight: 400;
  src: local('PT Sans Italic'), local('PTSans-Italic'),
    url('../assets/fonts/pt-sans-v8-latin-italic.woff2') format('woff2'),
    url('../assets/fonts/pt-sans-v8-latin-italic.woff') format('woff');
}

/* pt-sans-700 - latin */
@font-face {
  font-family: 'PT Sans';
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  src: local('PT Sans Bold'), local('PTSans-Bold'),
    url('../assets/fonts/pt-sans-v8-latin-700.woff2') format('woff2'),
    url('../assets/fonts/pt-sans-v8-latin-700.woff') format('woff');
}

/* pt-sans-700italic - latin */
@font-face {
  font-family: 'PT Sans';
  font-display: swap;
  font-style: italic;
  font-weight: 700;
  src: local('PT Sans Bold Italic'), local('PTSans-BoldItalic'),
    url('../assets/fonts/pt-sans-v8-latin-700italic.woff2') format('woff2'),
    url('../assets/fonts/pt-sans-v8-latin-700italic.woff') format('woff');
}

