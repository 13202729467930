/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */

.c-post {
  @include clearfix();
  margin-bottom: 2em;
}

.c-post__meta {
  @include clearfix();

  @include bp-medium {
    margin-bottom: 1em;
  }
}

.c-post__date {
  float: left;
}

.c-post__category {
  font-weight: bold;
  float: right;
  text-align: right;
  display: none;
}

.c-post__entry {
  h2 {
    font-size: rem(24);
    margin-bottom: 0.25em;
  }

  h3 {
    font-size: rem(21);
    margin-bottom: 0.25em;
  }

  ul, ol {
    margin-left: 2rem;
  }
}

.alignnone {
  height: auto !important;
  margin: 1em 1em 1em 0;
  width: auto !important;
}

.aligncenter {
  height: auto !important;
  margin: 1em auto;
  width: auto !important;
}

.alignright {
  float: right;
  margin: 1em 0 1em 1em;
}

.alignleft {
  float: left;
  margin: 1em 1em 1em 0;
}

.wp-caption {
  text-align: center;

  img {
    border: 0;
    height: auto !important;
    margin: 0;
    max-width: 100%;
    padding: 0;
    width: auto !important;
  }
}

.wp-caption-text {
  color: $color-alt;
  font-size: rem(16);
  font-style: italic;
  margin-top: 1em;
  text-align: center;
}
