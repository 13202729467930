/* Container */

.c-container {
  background: linear-gradient(180deg, $color-very-light-grey 0%, $color-very-light-grey2 100%);
  min-height: em(200);
  padding: em(20) em(20) 0 em(20);

  @include bp-small {
    min-height: em(300);
    padding: em(30) em(30) 0 em(30);
  }

  @include bp-medium {
    min-height: em(400);
    padding: em(40) em(40) 0 em(40);
  }

  @include bp-large {
    min-height: em(500);
    padding: em(50) em(50) 0 em(50);
  }

  @include bp-full {
    min-height: em(600);
    padding: em(60) em(60) 0 em(60);
  }
}
