/* Social Media */

.c-social-media {
  font-size: 0.89rem;
  margin: 0 0 2em 0;
}

.c-social-media__item {
  display: inline-block;
  list-style-type: none;
  margin-right: 0.75em;
}

.c-social-media__icon {
  fill: $color-white;
  height: 1.33em;
  width: 1.33em;
}
