
.c-content {
  background: $color-white;
  box-shadow: $global-box-shadow;
  margin: 0 auto;
  max-width: 1080px;
  min-height: rem(800);
  padding-bottom: rem(20);
}

.c-content__intro {
  max-width: 880px;
  margin: 0 auto;
  padding: rem(30);

  @include bp-medium {
    padding: rem(40) rem(30);
  }

  @include bp-large {
    padding: rem(50) rem(30);
  }

  @include bp-full {
    padding: rem(60) rem(30);
  }

  & p:last-child {
    margin-bottom: 0;
  }
}

.c-content__intro--just-heading {
  padding-bottom: 0;
}

.c-content__intro-text {
  font-size: rem(21);
  color: $color-emp-text;
}

.c-content__featured-image {
  display: block;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.c-content__featured-image-desc {
  color: $color-alt;
  font-size: rem(16);
  font-style: italic;
  text-align: center;
  margin: 2em;
}

.c-content__main {
  max-width: 786px;
  margin: 0 auto;
  padding: rem(20) rem(30);
  line-height: 1.7;

  @include bp-medium {
    padding: rem(40) rem(30);
  }
}
