
.c-category {
  background: $color-white;
  box-shadow: $global-box-shadow;
  margin-bottom: em(40);
  transition: $global-transition;
  will-change: transform;

  @include bp-large {
    margin-bottom: em(50);
  }

  @include bp-full {
    margin-bottom: em(60);
  }

  &:hover {
    box-shadow: $global-box-shadow-hover;
    transform: scale(1.03);
  }
}

.c-category__link {
  display: block;
  text-decoration: none;
}

.c-category__desc {
  padding: em(28);
}

.c-category__title {
  font-size: rem(24);
  margin: 0 0 0.25em 0;
  text-align: center;
}

.c-category__subcategory {
  color: $color-emp-text;
  margin: 0;
  text-align: center;
}


