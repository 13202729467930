
.c-download {
  margin: 0;
}

.c-download__item {
  list-style-type: none;
}

.c-download__icon {
  display: inline-block;
  fill: $color-emp-text;
  height: 15px;
  margin: 0.25em 0.5em 0 0;
  width: 13px;
}

.c-download__link {
  font-weight: bold;
}

.c-download__type {
  color: $color-faded-text;
  font-size: rem(16);
  display: inline-block;
  margin-left: 0.5em;
}
