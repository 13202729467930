/* Sec Lang */

.c-main-lang-nav {
  display: inline-block;
  margin: 0;
  position: absolute;
  top: 0;
  right: 2em;
  transition: $global-transition;

  @include bp-medium {
    border-radius: $global-radius;
    border: 1px solid $color-border;
    box-shadow: 0 2px 3px rgba(153, 153, 153, 0.1);
    position: absolute;
    top: em(62);
    right: em(35);
    width: em(56);

    &:hover .c-main-lang-nav__item:not(.current-lang) {
      display: block;
    }
  }

  @include bp-large {
    right: em(40);
  }

  @include bp-xlarge {
    right: em(45);
  }

  @include bp-full {
    right: em(50);
  }
}

.c-main-lang-nav__item {
  display: inline-block;
  list-style-type: none;
  margin: 0;

  &:first-child {
    border-right: 1px solid $color-border;
  }

  &.current-lang {
    font-weight: bold;
  }

  @include bp-medium {
    display: none;
    border: none;

    &.current-lang {
      display: block;
      font-weight: normal;

      .c-main-lang-nav__icon {
        display: block;
      }
    }
  }
}

.c-main-lang-nav__link {
  color: $color-medium-grey;
  font-size: rem(18);
  line-height: 1;
  padding: 0 rem(12);
  position: relative;
  text-decoration: none;

  @include bp-medium {
    border-radius: $global-radius;
    display: block;
    font-size: rem(16);
    height: rem(36);
    line-height: rem(36);
    padding: 0 rem(8);
    transition: $global-transition;

    &:hover {
      background: $color-fill;
      color: $color-medium-grey;
    }
  }
}

.c-main-lang-nav__icon {
  display: none;
  height: rem(8);
  position: absolute;
  right: rem(10);
  stroke: $color-faded-text;
  top: rem(15);
  width: rem(13);
}
