/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
  background: $color-white;
  height: em(160);
  position: relative;

  @include bp-medium {
    box-shadow: $global-box-shadow;
  }

  &.is-open {
    .c-header__dropdown {
      display: block;
    }

    .c-header__control--open {
      display: none;
    }

    .c-header__control--close {
      display: block;
    }

    @include bp-medium {
      .c-header__control--open,
      .c-header__control--close {
        display: none;
      }
    }
  }
}

.c-header__control {
  display: block;
  position: absolute;
  top: em(60);
  right: 2em;
  height: rem(30);
  width: rem(30);

  svg {
    height: rem(30);
    width: rem(30);
    stroke: $color-faded-text;
  }

  @include bp-medium {
    display: none;
  }
}

.c-header__control--close {
  display: none;

  svg {
    stroke: $color-faded-text;
    fill: transparent;
    stroke-width: 4;
  }
}

.c-header__dropdown {
  background: $color-white;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.06);
  display: none;
  left: 0;
  padding: 0 2em 2em 2em;
  position: absolute;
  top: em(160);
  width: 100%;
  z-index: 100;

  @include bp-medium {
    display: block;
    position: static;
    padding: 0;
  }
}
