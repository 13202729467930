/* Certificates */

.c-certificates {
  margin-bottom: 2em;
}

.c-certificates__title {
  color: $color-white;
  font-size: rem(14);
  margin-bottom: 0.5em;
}

.c-certificates__list {
  margin: 0;
}

.c-certificates__item {
  display: inline-block;
  font-size: rem(14);
  list-style-type: none;
  margin-right: 0.75em;
}

.c-certificates__link {
  color: $color-white;
  text-decoration: none;

  &:hover {
    color: $color-white;
    text-decoration: underline;
  }
}
