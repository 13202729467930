
.c-brands {
  @include clearfix();
  margin: 0 rem(-22) 0 0;

  @include bp-medium {
    margin: 0 rem(-32) 0 0;
  }

  @include bp-large {
    margin: 0 rem(-52) 0 0;
  }
}

.c-brands__thumb {
  background: $color-fill-alt;
  box-shadow: $global-box-shadow;
  float: left;
  height: rem(200);
  margin: 0 rem(22) rem(22) 0;
  transition: $global-transition;
  width: rem(200);

  @include bp-medium {
    margin: 0 rem(32) rem(32) 0;
  }

  @include bp-large {
    margin: 0 rem(52) rem(52) 0;
  }

  &:hover {
    box-shadow: $global-box-shadow-hover;
    transform: scale(1.05);
  }
}

.c-brands__link {
  color: $color-text;
  display: block;
  text-decoration: none;
}

.c-brands__placeholder {
  background: $color-white;
  height: rem(160);
  width: 100%;
  text-align: center;
}

.c-brands__image {
  display: block;
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.c-brands__type {
  color: $color-alt;
  font-size: rem(14);
  font-style: italic;
  margin: 0;
  padding: 0.5em;
  text-align: center;
}
