.c-map {
  padding: 0 1em;

  @include bp-medium {
    margin-bottom: -1em;
  }

  @include bp-large {
    margin-bottom: -2em;
  }
}

.c-map__image {
  display: block;
  width: 100%;
}
