
.c-tease-post {
  border-bottom: 1px solid $color-border;
  margin-bottom: rem(30);
  padding-bottom: rem(30);

  &:last-child {
    border: none;
  }
}

.c-tease-post__date {
  font-size: rem(14);
  color: $color-faded-text;
  margin-bottom: 0.5em;
}

.c-tease-post__title {
  font-size: rem(21);
  margin-bottom: 0.15em;

  a {
    color: $color-heading;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.c-tease-post__desc {
  font-size: rem(16);
  color: $color-alt;
}
