@charset "UTF-8";
/*
  Project: Merkanta
  Author: Lubos Kmetko
 */
/* Breakpoints
   ========================================================================== */
/* Colors
   ========================================================================== */
/* ==========================================================================
   #BOX-SIZING
   ========================================================================== */
/**
 * More sensible default box-sizing:
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 */
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

/* pt-sans-regular - latin */
@font-face {
  font-family: 'PT Sans';
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: local("PT Sans"), local("PTSans-Regular"), url("../assets/fonts/pt-sans-v8-latin-regular.woff2") format("woff2"), url("../assets/fonts/pt-sans-v8-latin-regular.woff") format("woff");
}

/* pt-sans-italic - latin */
@font-face {
  font-family: 'PT Sans';
  font-display: swap;
  font-style: italic;
  font-weight: 400;
  src: local("PT Sans Italic"), local("PTSans-Italic"), url("../assets/fonts/pt-sans-v8-latin-italic.woff2") format("woff2"), url("../assets/fonts/pt-sans-v8-latin-italic.woff") format("woff");
}

/* pt-sans-700 - latin */
@font-face {
  font-family: 'PT Sans';
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  src: local("PT Sans Bold"), local("PTSans-Bold"), url("../assets/fonts/pt-sans-v8-latin-700.woff2") format("woff2"), url("../assets/fonts/pt-sans-v8-latin-700.woff") format("woff");
}

/* pt-sans-700italic - latin */
@font-face {
  font-family: 'PT Sans';
  font-display: swap;
  font-style: italic;
  font-weight: 700;
  src: local("PT Sans Bold Italic"), local("PTSans-BoldItalic"), url("../assets/fonts/pt-sans-v8-latin-700italic.woff2") format("woff2"), url("../assets/fonts/pt-sans-v8-latin-700italic.woff") format("woff");
}

/* ==========================================================================
   #NORMALIZE
   ========================================================================== */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0;
}

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block;
}

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px;
}

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
     * Add the correct display in IE.
     */
main {
  display: block;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit;
}

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic;
}

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000;
}

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%;
}

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block;
}

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none;
}

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible;
}

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible;
}

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */
}

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto;
}

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block;
}

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item;
}

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block;
}

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block;
}

/**
     * Add the correct display in IE.
     */
template {
  display: none;
}

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none;
}

/* ==========================================================================
   #RESET
   ========================================================================== */
/**
 * A very simple reset that sits on top of Normalize.css.
 */
body,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, dd, ol, ul,
figure,
hr,
fieldset, legend {
  margin: 0;
  padding: 0;
}

/**
 * Remove trailing margins from nested lists.
 */
li > ol,
li > ul {
  margin-bottom: 0;
}

/**
 * Remove default table spacing.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
 * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
 *    on fieldsets.
 */
fieldset {
  min-width: 0;
  /* [1] */
  border: 0;
}

/* ==========================================================================
   #SHARED
   ========================================================================== */
/**
 * Shared declarations for certain elements.
 */
/**
 * Always declare margins in the same direction:
 * csswizardry.com/2012/06/single-direction-margin-declarations
 */
address,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, ol, ul,
figure,
hr,
table,
fieldset {
  margin-bottom: 1.3rem;
}

/**
 * Consistent indentation for lists.
 */
dd, ol, ul {
  margin-left: 1rem;
}

/* ==========================================================================
   #BLOCKQUOTES
   ========================================================================== */
blockquote {
  quotes: '“' '”';
  font-style: italic;
}

blockquote p:first-child:before {
  content: open-quote;
}

/* ==========================================================================
   #FORMS
   ========================================================================== */
input, textarea {
  font-family: "PT Sans", "Segoe UI", "Roboto", "Helvetica Neue", "Arial", sans-serif;
}

/* ==========================================================================
   #HEADINGS
   ========================================================================== */
/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */
h1, h2, h3, h4, h5, h6 {
  color: #006940;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.75rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}

/* ==========================================================================
   #HR
   ========================================================================== */
hr {
  display: block;
}

/* ==========================================================================
   #PAGE
   ========================================================================== */
/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */
html {
  background: #fff;
  color: #252525;
  font-family: "PT Sans", "Segoe UI", "Roboto", "Helvetica Neue", "Arial", sans-serif;
  font-size: 0.875rem;
  /* [1] */
  /* 13px */
  line-height: 1.5;
  /* [1] */
  min-height: 100%;
  /* [3] */
  overflow-y: scroll;
  /* [2] */
}

@media (min-width: 768px) {
  html {
    font-size: 0.9375em;
    /* 15px */
  }
}

@media (min-width: 1024px) {
  html {
    font-size: 1em;
    /* 16px */
  }
}

@media (min-width: 1600px) {
  html {
    font-size: 1.125em;
    /* 18px */
  }
}

/* ==========================================================================
   #IMAGES
   ========================================================================== */
/**
 * 1. Fluid images for responsive purposes.
 * 2. Offset `alt` text from surrounding copy.
 * 3. Setting `vertical-align` removes the whitespace that appears under `img`
 *    elements when they are dropped into a page as-is. Safer alternative to
 *    using `display: block;`.
 */
img {
  font-style: italic;
  /* [2] */
  max-width: 100%;
  /* [1] */
  vertical-align: middle;
  /* [3] */
}

/**
 * 1. If a `width` and/or `height` attribute have been explicitly defined, let’s
 *    not make the image fluid.
 */
img[width],
img[height] {
  max-width: none;
}

/* ==========================================================================
   #LINKS
   ========================================================================== */
a {
  color: #2a608d;
  text-decoration: underline;
}

a:hover {
  color: #073964;
}

/* ==========================================================================
   #LISTS
   ========================================================================== */
li {
  margin-bottom: 0.5rem;
}

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1rem;
  margin-top: 0.5rem;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 1rem;
  margin-left: 1rem;
}

/* ==========================================================================
   #TABLES
   ========================================================================== */
/**
 * 1. Ensure tables fill up as much space as possible.
 */
table {
  width: 100%;
  /* [1] */
}

/* ==========================================================================
   #LAYOUT
   ========================================================================== */
/* Simple flexbox layout system */
.o-layout {
  display: block;
}

@media (min-width: 768px) {
  .o-layout {
    display: flex;
  }
}

.o-layout__item {
  flex: 1;
}

.o-layout--guttered {
  justify-content: space-between;
}

.o-layout--guttered > .o-layout__item {
  flex: none;
}

.o-layout--wrapped {
  flex-wrap: wrap;
}

@media (min-width: 768px) {
  .o-layout--2 > .o-layout__item {
    width: 48%;
  }
}

@media (min-width: 768px) {
  .o-layout--3 > .o-layout__item {
    width: 31%;
  }
}

@media (min-width: 768px) {
  .o-layout--4 > .o-layout__item {
    width: 23%;
  }
}

/* ==========================================================================
   #LIST-BARE
   ========================================================================== */
/**
 * Strip list-like appearance from lists by removing their bullets, and any
 * indentation.
 */
.o-list-bare {
  list-style: none;
  margin-left: 0;
}

/* ==========================================================================
   #LIST-INLINE
   ========================================================================== */
/**
 * The list-inline object simply displays a list of items in one line.
 */
.o-list-inline {
  list-style: none;
  margin-left: 0;
}

.o-list-inline__item {
  display: inline-block;
}

/* ==========================================================================
   #MEDIA
   ========================================================================== */
/**
 * Place any image- and text-like content side-by-side, as per:
 * http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code
 */
.o-media {
  display: block;
}

.o-media:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.o-media__img {
  float: left;
  margin-right: 1.66667rem;
  width: 31%;
}

.o-media__img > img {
  display: block;
}

.o-media__body {
  display: block;
  overflow: hidden;
}

.o-media__body,
.o-media__body > :last-child {
  margin-bottom: 0;
}

/* Reversed media objects
   ========================================================================== */
.o-media--reverse > .o-media__img {
  float: right;
  margin-left: 1rem;
  margin-right: 0;
}

/* Gutterless media objects
   ========================================================================== */
.o-media--flush > .o-media__img {
  margin-left: 0;
  margin-right: 0;
}

/* ==========================================================================
   #TABLE
   ========================================================================== */
/**
 * A simple object for manipulating the structure of HTML `table`s.
 */
.o-table {
  width: 100%;
}

/* Equal-width table cells.
   ========================================================================== */
/**
 * `table-layout: fixed` forces all cells within a table to occupy the same
 * width as each other. This also has performance benefits: because the browser
 * does not need to (re)calculate cell dimensions based on content it discovers,
 * the table can be rendered very quickly. Further reading:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/table-layout#Values
 */
.o-table--fixed {
  table-layout: fixed;
}

/* ==========================================================================
   #WRAPPER
   ========================================================================== */
/**
 * Page-level constraining and wrapping elements.
 */
.o-wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 1460px;
  padding-bottom: 1px;
}

.o-wrapper:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

/* Address */
.c-address {
  margin-bottom: 2em;
}

.c-address a {
  color: #fff;
  text-decoration: none;
}

.c-address a:hover {
  text-decoration: underline;
}

.c-address__title {
  font-size: 1.16667rem;
  margin-bottom: 0.5em;
}

.c-address__subtitle {
  color: #fff;
  font-size: 0.88889rem;
  margin-bottom: 0.5em;
}

.c-address__text {
  font-size: 0.77778rem;
}

.c-address__list {
  font-size: 0.77778rem;
  list-style: none;
  margin: 1.3em 0 0 0;
}

.c-address__list-item {
  margin: 0;
}

.c-address__list-item strong {
  display: inline-block;
  width: 4em;
}

.c-address--alt {
  margin: 0;
}

.c-address--alt a {
  color: #2a608d;
}

.c-address--alt .c-address__subtitle {
  color: #252525;
  font-size: 1rem;
}

.c-address--alt .c-address__text,
.c-address--alt .c-address__list {
  font-size: 1rem;
  margin-top: 0;
}

.c-box {
  background: #fff;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.06);
  padding: 1.66667em 1.11111em;
  margin-bottom: 2.22222em;
}

@media (min-width: 480px) {
  .c-box {
    padding: 1.66667em 2.22222em;
    margin-bottom: 2.22222em;
  }
}

@media (min-width: 768px) {
  .c-box {
    padding: 1.94444em 2.77778em;
    margin-bottom: 2.22222em;
  }
}

@media (min-width: 1024px) {
  .c-box {
    padding: 2.5em 3.33333em;
    margin-bottom: 2.77778em;
  }
}

@media (min-width: 1600px) {
  .c-box {
    padding: 3.05556em 3.88889em;
    margin-bottom: 3.33333em;
  }
}

.c-box--narrow {
  margin: 0 auto 3em auto;
  max-width: 1080px;
}

.c-box--narrow p:last-child {
  margin: 0;
}

.c-box--small-bottom-padding {
  padding-bottom: 0.55556em;
}

.c-box--no-bottom-padding {
  padding-bottom: 0;
}

.c-brand {
  background: #fff;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.06);
  padding: 1.66667em;
  margin-bottom: 2.22222em;
}

@media (min-width: 768px) {
  .c-brand {
    padding: 2.22222em;
    margin-bottom: 2.77778em;
  }
}

@media (min-width: 1024px) {
  .c-brand {
    padding: 3.05556em 3.88889em;
    margin-bottom: 3.33333em;
  }
}

.c-brand-desc {
  margin-bottom: 3em;
}

@media (min-width: 768px) {
  .c-brand-desc {
    border-right: 1px solid #e9e9e9;
    margin-bottom: 0;
    padding-right: 2em;
  }
}

.c-brand__title {
  font-size: 2rem;
  margin-bottom: 0.5em;
}

.c-brand__description {
  font-size: 1.16667rem;
  color: #073964;
}

.c-brand__text p:last-child {
  margin-bottom: 0;
}

.c-brand__subtitle {
  color: #073964;
  font-size: 1rem;
  margin: 2em 0 0 0;
}

.c-brand__url {
  font-weight: bold;
  margin: 2em 0 0 0;
}

.c-brands {
  margin: 0 -1.22222rem 0 0;
}

.c-brands:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

@media (min-width: 768px) {
  .c-brands {
    margin: 0 -1.77778rem 0 0;
  }
}

@media (min-width: 1024px) {
  .c-brands {
    margin: 0 -2.88889rem 0 0;
  }
}

.c-brands__thumb {
  background: #f2f2f2;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.06);
  float: left;
  height: 11.11111rem;
  margin: 0 1.22222rem 1.22222rem 0;
  transition: all 200ms ease-in-out;
  width: 11.11111rem;
}

@media (min-width: 768px) {
  .c-brands__thumb {
    margin: 0 1.77778rem 1.77778rem 0;
  }
}

@media (min-width: 1024px) {
  .c-brands__thumb {
    margin: 0 2.88889rem 2.88889rem 0;
  }
}

.c-brands__thumb:hover {
  box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
}

.c-brands__link {
  color: #252525;
  display: block;
  text-decoration: none;
}

.c-brands__placeholder {
  background: #fff;
  height: 8.88889rem;
  width: 100%;
  text-align: center;
}

.c-brands__image {
  display: block;
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.c-brands__type {
  color: #666;
  font-size: 0.77778rem;
  font-style: italic;
  margin: 0;
  padding: 0.5em;
  text-align: center;
}

/* ==========================================================================
   #BUTTONS
   ========================================================================== */
/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */
/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */
.c-btn {
  border-radius: 3px;
  border: 0;
  cursor: pointer;
  /* [5] */
  display: inline-block;
  /* [1] */
  font: inherit;
  /* [3] */
  margin: 0;
  /* [4] */
  padding: 0.25rem 1rem;
  text-align: center;
  /* [4] */
  transition: all 200ms ease-in-out;
  vertical-align: middle;
  /* [2] */
}

/* Style variants
   ========================================================================== */
.c-btn--primary {
  background-color: #3b8900;
  color: #fff;
}

.c-btn--primary, .c-btn--primary:hover, .c-btn--primary:active, .c-btn--primary:focus {
  text-decoration: none;
  /* [4] */
  color: #fff;
}

.c-btn--primary:hover, .c-btn--primary:focus {
  background-color: #006940;
}

/* Size variants
   ========================================================================== */
.c-btn--small {
  padding: 0.25rem 0.5rem;
}

.c-btn--large {
  padding: 0.75rem 1.5rem;
}

.c-category {
  background: #fff;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.06);
  margin-bottom: 2.22222em;
  transition: all 200ms ease-in-out;
  will-change: transform;
}

@media (min-width: 1024px) {
  .c-category {
    margin-bottom: 2.77778em;
  }
}

@media (min-width: 1600px) {
  .c-category {
    margin-bottom: 3.33333em;
  }
}

.c-category:hover {
  box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.2);
  transform: scale(1.03);
}

.c-category__link {
  display: block;
  text-decoration: none;
}

.c-category__desc {
  padding: 1.55556em;
}

.c-category__title {
  font-size: 1.33333rem;
  margin: 0 0 0.25em 0;
  text-align: center;
}

.c-category__subcategory {
  color: #073964;
  margin: 0;
  text-align: center;
}

/* Certificates */
.c-certificates {
  margin-bottom: 2em;
}

.c-certificates__title {
  color: #fff;
  font-size: 0.77778rem;
  margin-bottom: 0.5em;
}

.c-certificates__list {
  margin: 0;
}

.c-certificates__item {
  display: inline-block;
  font-size: 0.77778rem;
  list-style-type: none;
  margin-right: 0.75em;
}

.c-certificates__link {
  color: #fff;
  text-decoration: none;
}

.c-certificates__link:hover {
  color: #fff;
  text-decoration: underline;
}

/* ==========================================================================
   #COMMENT FORM
   Here we are using default HTML structure and classes,
   to create custom ones use comment_form_defaults filter
   ========================================================================== */
.c-comment-form {
  margin-top: 2em;
}

.c-comment-form label {
  display: block;
}

.c-comment-form textarea {
  max-width: 100%;
}

.comment-reply-title {
  margin-bottom: 0.25em;
}

/* ==========================================================================
   #COMMENT
   ========================================================================== */
.c-comment {
  margin-bottom: 1em;
}

.c-comment__author {
  font-size: 1rem;
  margin-bottom: 0.25em;
}

.c-comment__moderation {
  font-style: italic;
  margin-bottom: 0.5em;
}

/* Container */
.c-container {
  background: linear-gradient(180deg, #e9e9e9 0%, #fafafa 100%);
  min-height: 11.11111em;
  padding: 1.11111em 1.11111em 0 1.11111em;
}

@media (min-width: 480px) {
  .c-container {
    min-height: 16.66667em;
    padding: 1.66667em 1.66667em 0 1.66667em;
  }
}

@media (min-width: 768px) {
  .c-container {
    min-height: 22.22222em;
    padding: 2.22222em 2.22222em 0 2.22222em;
  }
}

@media (min-width: 1024px) {
  .c-container {
    min-height: 27.77778em;
    padding: 2.77778em 2.77778em 0 2.77778em;
  }
}

@media (min-width: 1600px) {
  .c-container {
    min-height: 33.33333em;
    padding: 3.33333em 3.33333em 0 3.33333em;
  }
}

.c-content {
  background: #fff;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.06);
  margin: 0 auto;
  max-width: 1080px;
  min-height: 44.44444rem;
  padding-bottom: 1.11111rem;
}

.c-content__intro {
  max-width: 880px;
  margin: 0 auto;
  padding: 1.66667rem;
}

@media (min-width: 768px) {
  .c-content__intro {
    padding: 2.22222rem 1.66667rem;
  }
}

@media (min-width: 1024px) {
  .c-content__intro {
    padding: 2.77778rem 1.66667rem;
  }
}

@media (min-width: 1600px) {
  .c-content__intro {
    padding: 3.33333rem 1.66667rem;
  }
}

.c-content__intro p:last-child {
  margin-bottom: 0;
}

.c-content__intro--just-heading {
  padding-bottom: 0;
}

.c-content__intro-text {
  font-size: 1.16667rem;
  color: #073964;
}

.c-content__featured-image {
  display: block;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.c-content__featured-image-desc {
  color: #666;
  font-size: 0.88889rem;
  font-style: italic;
  text-align: center;
  margin: 2em;
}

.c-content__main {
  max-width: 786px;
  margin: 0 auto;
  padding: 1.11111rem 1.66667rem;
  line-height: 1.7;
}

@media (min-width: 768px) {
  .c-content__main {
    padding: 2.22222rem 1.66667rem;
  }
}

.c-copyright {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 0.66667rem;
  padding-top: 1em;
}

.c-download {
  margin: 0;
}

.c-download__item {
  list-style-type: none;
}

.c-download__icon {
  display: inline-block;
  fill: #073964;
  height: 15px;
  margin: 0.25em 0.5em 0 0;
  width: 13px;
}

.c-download__link {
  font-weight: bold;
}

.c-download__type {
  color: #999;
  font-size: 0.88889rem;
  display: inline-block;
  margin-left: 0.5em;
}

/* ==========================================================================
   #FOOTER
   ========================================================================== */
.c-footer {
  background: linear-gradient(101.04deg, #2a608d 0.25%, #006940 97.78%);
  color: #fff;
  padding: 1em;
}

@media (min-width: 1024px) {
  .c-footer {
    padding: 2em 2em 1em 2em;
  }
}

@media (min-width: 1600px) {
  .c-footer {
    padding: 3em 3em 1em 3em;
  }
}

.c-form-box {
  border-radius: 3px;
  border: 1px solid #e9e9e9;
  box-shadow: 0 2px 3px rgba(153, 153, 153, 0.1);
  margin-top: 1em;
  padding: 1.33333em;
}

@media (min-width: 768px) {
  .c-form-box {
    margin-top: 2em;
    padding: 1.88889em;
  }
}

@media (min-width: 1024px) {
  .c-form-box {
    margin-top: 3em;
    padding: 2.44444em;
  }
}

.c-form-box__title {
  font-size: 1.33333rem;
  margin: 0 auto 1em auto;
  max-width: 500px;
  text-align: center;
}

.c-form-box__desc {
  color: #666;
  margin: 0 auto;
  max-width: 500px;
  text-align: center;
}

.c-form__note {
  color: #999;
  font-size: 0.77778rem;
  font-style: italic;
  margin: 0.5em 0 3em 0;
  text-align: right;
}

.c-form {
  max-width: 500px;
  margin: 0 auto;
}

.c-form__error {
  color: #eb5757;
  font-size: 0.88889rem;
  font-style: italic;
  margin: 1em 0;
  text-align: center;
}

.c-form__label {
  color: #073964;
  display: block;
  font-weight: bold;
  margin: 1em 0 0.25em 0.5em;
}

.c-form__input {
  border: 1px solid #e9e9e9;
  border-radius: 3px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.06);
  font-size: 0.88889rem;
  line-height: 1.5;
  padding: 0.5em 1em;
  width: 100%;
}

.c-form__s {
  display: none;
}

.c-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.c-gallery__item {
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.06);
  display: block;
  margin-bottom: 2em;
  width: 31%;
  transition: all 200ms ease-in-out;
}

.c-gallery__item:hover {
  box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.2);
}

.c-gallery__item--empty {
  box-shadow: none;
}

.c-gallery__item--empty:hover {
  box-shadow: none;
}

.c-gallery__image {
  display: block;
}

/* ==========================================================================
   #HEADER
   ========================================================================== */
.c-header {
  background: #fff;
  height: 8.88889em;
  position: relative;
}

@media (min-width: 768px) {
  .c-header {
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.06);
  }
}

.c-header.is-open .c-header__dropdown {
  display: block;
}

.c-header.is-open .c-header__control--open {
  display: none;
}

.c-header.is-open .c-header__control--close {
  display: block;
}

@media (min-width: 768px) {
  .c-header.is-open .c-header__control--open,
  .c-header.is-open .c-header__control--close {
    display: none;
  }
}

.c-header__control {
  display: block;
  position: absolute;
  top: 3.33333em;
  right: 2em;
  height: 1.66667rem;
  width: 1.66667rem;
}

.c-header__control svg {
  height: 1.66667rem;
  width: 1.66667rem;
  stroke: #999;
}

@media (min-width: 768px) {
  .c-header__control {
    display: none;
  }
}

.c-header__control--close {
  display: none;
}

.c-header__control--close svg {
  stroke: #999;
  fill: transparent;
  stroke-width: 4;
}

.c-header__dropdown {
  background: #fff;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.06);
  display: none;
  left: 0;
  padding: 0 2em 2em 2em;
  position: absolute;
  top: 8.88889em;
  width: 100%;
  z-index: 100;
}

@media (min-width: 768px) {
  .c-header__dropdown {
    display: block;
    position: static;
    padding: 0;
  }
}

@keyframes c-heading-full-line {
  to {
    width: 100%;
  }
}

@keyframes c-heading-opacity {
  to {
    opacity: 1;
  }
}

@keyframes c-heading-fade-in-up {
  from {
    opacity: 0;
    transform: translate3d(0, 50%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

.c-heading {
  color: #3b8900;
  display: inline-block;
  font-size: 1.72222rem;
  font-style: italic;
  margin: 0 0 2.22222rem 0;
  opacity: 0;
  will-change: transform;
}

.c-heading em {
  font-weight: normal;
  color: #006940;
}

.c-heading:after {
  background: #c4c4c4;
  content: '';
  display: block;
  height: 5px;
  margin-top: 0.15em;
  width: 0;
}

.c-heading.is-visible {
  animation: c-heading-fade-in-up 500ms ease-in-out forwards;
}

.c-heading.is-visible:after {
  animation: c-heading-full-line 400ms ease-in-out forwards;
  animation-delay: 200ms;
}

.c-image {
  margin-bottom: 0;
}

.c-image__placeholder {
  background: #e9e9e9;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.06);
  height: 0;
  overflow: hidden;
  padding-top: 66.6667%;
  position: relative;
  width: 100%;
}

.c-image__placeholder img,
.c-image__placeholder iframe {
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.c-image__desc {
  color: #666;
  font-size: 0.88889rem;
  font-style: italic;
  margin-top: 1em;
  text-align: center;
}

.c-logo__img {
  height: 3.16667em;
  position: absolute;
  width: 8.33333em;
  top: 2.83333em;
  left: 2em;
}

@media (min-width: 768px) {
  .c-logo__img {
    top: 2.83333em;
    left: 1.11111em;
  }
}

@media (min-width: 1024px) {
  .c-logo__img {
    left: 1.66667em;
  }
}

@media (min-width: 1280px) {
  .c-logo__img {
    left: 2.22222em;
  }
}

@media (min-width: 1600px) {
  .c-logo__img {
    left: 2.77778em;
  }
}

/* Sec Lang */
.c-main-lang-nav {
  display: inline-block;
  margin: 0;
  position: absolute;
  top: 0;
  right: 2em;
  transition: all 200ms ease-in-out;
}

@media (min-width: 768px) {
  .c-main-lang-nav {
    border-radius: 3px;
    border: 1px solid #e9e9e9;
    box-shadow: 0 2px 3px rgba(153, 153, 153, 0.1);
    position: absolute;
    top: 3.44444em;
    right: 1.94444em;
    width: 3.11111em;
  }
  .c-main-lang-nav:hover .c-main-lang-nav__item:not(.current-lang) {
    display: block;
  }
}

@media (min-width: 1024px) {
  .c-main-lang-nav {
    right: 2.22222em;
  }
}

@media (min-width: 1280px) {
  .c-main-lang-nav {
    right: 2.5em;
  }
}

@media (min-width: 1600px) {
  .c-main-lang-nav {
    right: 2.77778em;
  }
}

.c-main-lang-nav__item {
  display: inline-block;
  list-style-type: none;
  margin: 0;
}

.c-main-lang-nav__item:first-child {
  border-right: 1px solid #e9e9e9;
}

.c-main-lang-nav__item.current-lang {
  font-weight: bold;
}

@media (min-width: 768px) {
  .c-main-lang-nav__item {
    display: none;
    border: none;
  }
  .c-main-lang-nav__item.current-lang {
    display: block;
    font-weight: normal;
  }
  .c-main-lang-nav__item.current-lang .c-main-lang-nav__icon {
    display: block;
  }
}

.c-main-lang-nav__link {
  color: #999;
  font-size: 1rem;
  line-height: 1;
  padding: 0 0.66667rem;
  position: relative;
  text-decoration: none;
}

@media (min-width: 768px) {
  .c-main-lang-nav__link {
    border-radius: 3px;
    display: block;
    font-size: 0.88889rem;
    height: 2rem;
    line-height: 2rem;
    padding: 0 0.44444rem;
    transition: all 200ms ease-in-out;
  }
  .c-main-lang-nav__link:hover {
    background: #e9e9e9;
    color: #999;
  }
}

.c-main-lang-nav__icon {
  display: none;
  height: 0.44444rem;
  position: absolute;
  right: 0.55556rem;
  stroke: #999;
  top: 0.83333rem;
  width: 0.72222rem;
}

/* ==========================================================================
   #MAIN NAV
   ========================================================================== */
.c-main-nav {
  border-top: 1px solid #e9e9e9;
  margin: 2.5em 0 0 0;
}

@media (min-width: 768px) {
  .c-main-nav {
    border: 0;
    display: flex;
    left: 12.5em;
    margin: 0;
    position: absolute;
    top: 3.61111em;
  }
}

@media (min-width: 1024px) {
  .c-main-nav {
    left: 13.88889em;
  }
}

@media (min-width: 1280px) {
  .c-main-nav {
    left: 16.66667em;
  }
}

@media (min-width: 1600px) {
  .c-main-nav {
    left: 19.44444em;
  }
}

.c-main-nav__item {
  list-style: none;
  margin-bottom: 0;
  margin-top: 0;
  margin-left: 0;
  padding: 0;
}

@media (min-width: 768px) {
  .c-main-nav__item {
    margin-right: 1.38889em;
  }
}

@media (min-width: 1024px) {
  .c-main-nav__item {
    margin-right: 1.94444em;
  }
}

@media (min-width: 1280px) {
  .c-main-nav__item {
    margin-right: 2.5em;
  }
}

@media (min-width: 1600px) {
  .c-main-nav__item {
    margin-right: 3.05556em;
  }
}

.c-main-nav__link {
  color: #666;
  border-bottom: 1px solid #e9e9e9;
  display: block;
  text-decoration: none;
  padding: 0.75em 0;
}

@media (min-width: 768px) {
  .c-main-nav__link {
    border-bottom: 0;
    padding: 0.5em 0;
  }
  .c-main-nav__link:after {
    background: #006940;
    content: '';
    display: block;
    height: 2px;
    margin-top: 0.25em;
    transition: all 200ms ease-in-out;
    width: 0;
  }
  .c-main-nav__link:hover {
    color: #006940;
  }
  .c-main-nav__link:hover:after {
    width: 100%;
  }
}

.current-menu-item .c-main-nav__link {
  color: #006940;
  border-bottom-color: #006940;
  font-weight: bold;
}

.current-menu-item .c-main-nav__link:after {
  width: 100%;
}

.c-map {
  padding: 0 1em;
}

@media (min-width: 768px) {
  .c-map {
    margin-bottom: -1em;
  }
}

@media (min-width: 1024px) {
  .c-map {
    margin-bottom: -2em;
  }
}

.c-map__image {
  display: block;
  width: 100%;
}

/* Newsletter */
.c-newsletter {
  margin-bottom: 2em;
}

.c-newsletter__title {
  color: #fff;
  font-size: 0.88889rem;
  margin-bottom: 0.5em;
}

.c-newsletter__desc {
  font-size: 0.77778rem;
  margin-bottom: 0.5em;
}

.c-newsletter__form {
  display: flex;
  margin-top: 1em;
}

.c-newsletter__input {
  background: #fff;
  border-radius: 3px 0 0 3px;
  border-left: 1px solid #e9e9e9;
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.06);
  display: block;
  font-size: 0.77778rem;
  height: 36px;
  padding: 0 0.5em;
}

.c-newsletter__input::placeholder {
  color: #999;
}

.c-newsletter__btn {
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.06);
  border-radius: 0 3px 3px 0;
  font-size: 0.88889rem;
  font-weight: bold;
}

.c-page-intro {
  background: #fff;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.06);
  padding: 1.66667em;
  margin-bottom: 2.22222em;
}

@media (min-width: 480px) {
  .c-page-intro {
    padding: 1.66667em 2.22222em;
    margin-bottom: 2.22222em;
  }
}

@media (min-width: 768px) {
  .c-page-intro {
    padding: 1.94444em 2.77778em;
    margin-bottom: 2.22222em;
  }
}

@media (min-width: 1024px) {
  .c-page-intro {
    padding: 2.5em 3.33333em;
    margin-bottom: 2.77778em;
  }
}

@media (min-width: 1600px) {
  .c-page-intro {
    padding: 3.05556em 3.88889em;
    margin-bottom: 3.33333em;
  }
}

.c-page-intro__title {
  font-size: 2rem;
  margin-bottom: 0.5em;
}

.c-page-intro__text {
  font-size: 1.16667rem;
  color: #073964;
}

.c-page-intro__text p:last-child {
  margin-bottom: 0;
}

.c-pagination:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.c-pagination__link {
  display: block;
  font-weight: bold;
  text-decoration: none;
}

.c-pagination__icon {
  display: inline-block;
  fill: #2a608d;
  height: 0.44444rem;
  vertical-align: middle;
  width: 0.72222rem;
  stroke: #2a608d;
}

.c-pagination__icon:hover {
  stroke: #073964;
}

.c-pagination__link--newer {
  float: right;
}

.c-pagination__link--newer .c-pagination__icon {
  margin-left: 0.25em;
  transform: rotate(-90deg);
}

.c-pagination__link--older {
  float: left;
}

.c-pagination__link--older .c-pagination__icon {
  margin-right: 0.25em;
  transform: rotate(90deg);
}

/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */
.c-post {
  margin-bottom: 2em;
}

.c-post:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.c-post__meta:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

@media (min-width: 768px) {
  .c-post__meta {
    margin-bottom: 1em;
  }
}

.c-post__date {
  float: left;
}

.c-post__category {
  font-weight: bold;
  float: right;
  text-align: right;
  display: none;
}

.c-post__entry h2 {
  font-size: 1.33333rem;
  margin-bottom: 0.25em;
}

.c-post__entry h3 {
  font-size: 1.16667rem;
  margin-bottom: 0.25em;
}

.c-post__entry ul, .c-post__entry ol {
  margin-left: 2rem;
}

.alignnone {
  height: auto !important;
  margin: 1em 1em 1em 0;
  width: auto !important;
}

.aligncenter {
  height: auto !important;
  margin: 1em auto;
  width: auto !important;
}

.alignright {
  float: right;
  margin: 1em 0 1em 1em;
}

.alignleft {
  float: left;
  margin: 1em 1em 1em 0;
}

.wp-caption {
  text-align: center;
}

.wp-caption img {
  border: 0;
  height: auto !important;
  margin: 0;
  max-width: 100%;
  padding: 0;
  width: auto !important;
}

.wp-caption-text {
  color: #666;
  font-size: 0.88889rem;
  font-style: italic;
  margin-top: 1em;
  text-align: center;
}

/* Product Navigation */
.c-product-nav {
  font-size: 0.89rem;
}

.c-product-thumb {
  background: #fff;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.06);
  margin-bottom: 2.77778em;
  transition: all 200ms ease-in-out;
  will-change: transform;
}

.c-product-thumb:hover {
  box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.2);
  transform: scale(1.03);
}

.c-product-thumb__link {
  display: block;
  text-decoration: none;
}

.c-product-thumb__title-wrapper {
  display: flex;
  height: 5rem;
  padding: 0 1em;
}

.c-product-thumb__title {
  font-size: 1.16667rem;
  text-align: center;
  margin: auto;
}

.c-product__meta {
  margin-bottom: 3em;
}

.c-product__meta:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.c-product__category {
  color: #666;
  float: left;
  margin: 0;
  width: 50%;
}

.c-product__category-link {
  display: block;
  font-weight: bold;
  margin-top: 0.5em;
}

.c-product__logo {
  float: right;
  margin-top: 1em;
  text-align: right;
}

.c-search {
  display: flex;
}

@media (min-width: 768px) {
  .c-search {
    position: absolute;
    right: 6.66667em;
    top: 3.44444em;
  }
}

@media (min-width: 1024px) {
  .c-search {
    right: 7.22222em;
  }
}

@media (min-width: 1280px) {
  .c-search {
    right: 7.77778em;
  }
}

@media (min-width: 1600px) {
  .c-search {
    right: 8.33333em;
  }
}

.c-search__input {
  background: #fff;
  border-radius: 3px 0 0 3px;
  border-left: 1px solid #e9e9e9;
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.06);
  display: block;
  font-size: 0.77778rem;
  height: 2rem;
  padding: 0 0.5em;
  width: 13.61111em;
}

@media (min-width: 1024px) {
  .c-search__input {
    right: 14.16667em;
  }
}

@media (min-width: 1280px) {
  .c-search__input {
    right: 14.72222em;
  }
}

@media (min-width: 1600px) {
  .c-search__input {
    right: 15.27778em;
  }
}

.c-search__input::placeholder {
  color: #999;
}

.c-search__icon {
  display: block;
  width: 1rem;
  height: 1rem;
  fill: #fff;
}

.c-search__btn {
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.06);
  border-radius: 0 3px 3px 0;
  font-size: 0.88889rem;
  font-weight: bold;
  height: 2rem;
  padding: 0.55556rem 0.66667rem;
}

.c-search__btn input {
  display: none;
}

/* Secondary Lang Navigation */
.c-sec-lang-nav {
  font-size: 0.77778rem;
  margin: 0 0 2em 0;
}

.c-sec-lang-nav__item {
  display: inline-block;
  line-height: 1;
  list-style-type: none;
}

.c-sec-lang-nav__item:first-child {
  border-right: 1px solid #fff;
  margin-right: 0.25em;
  padding-right: 0.5em;
}

.c-sec-lang-nav__item.current-lang {
  font-weight: bold;
}

.c-sec-lang-nav__link {
  color: #fff;
  text-decoration: none;
}

.c-sec-lang-nav__link:hover {
  color: #fff;
  text-decoration: underline;
}

/* Sec Navigation */
.c-sec-nav {
  margin: 0 0 2em 0;
}

.c-sec-nav__item {
  font-size: 0.77778rem;
  list-style-type: none;
}

.c-sec-nav__item:first-child {
  font-size: 0.88889rem;
  font-weight: bold;
}

.c-sec-nav__link {
  color: #fff;
  text-decoration: none;
}

.c-sec-nav__link:hover {
  color: #fff;
  text-decoration: underline;
}

/* Social Media */
.c-social-media {
  font-size: 0.89rem;
  margin: 0 0 2em 0;
}

.c-social-media__item {
  display: inline-block;
  list-style-type: none;
  margin-right: 0.75em;
}

.c-social-media__icon {
  fill: #fff;
  height: 1.33em;
  width: 1.33em;
}

/* ==========================================================================
   #STYLEGUIDE
   ========================================================================== */
.c-style-guide__intro {
  border: 1px solid #ccc;
  border-bottom-width: 2px;
  border-right-width: 2px;
  margin: 2em 0 1em 0;
  padding: 2em;
}

.c-style-guide__title {
  color: #666;
  font-size: 1.25rem;
  font-weight: normal;
  margin: 0 0 0.25em 0;
  text-transform: uppercase;
}

.c-style-guide__desc {
  color: #666;
  margin: 0;
}

.c-style-guide__section {
  padding: 1rem 0;
}

.c-style-guide__section:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.c-style-guide__section-title {
  border-bottom: 1px solid #ccc;
  clear: both;
  color: #666;
  font-size: 1.25rem;
  font-weight: normal;
  padding-bottom: 0.5em;
  text-transform: uppercase;
  width: 50%;
}

.c-style-guide__section-subtitle {
  border-left: 6px solid #ccc;
  clear: both;
  color: #999;
  font-size: 1.125rem;
  font-weight: normal;
  margin: 2em 0;
  padding-left: 1em;
  text-transform: uppercase;
}

.c-subheading {
  border-bottom: 1px solid #e9e9e9;
  color: #999;
  font-size: 1rem;
  font-weight: normal;
  margin: 3em 0 2em 0;
  padding-bottom: 0.5em;
}

.js-tab.is-hidden {
  display: none;
}

.c-tabs {
  margin-top: 3em;
}

.c-tabs__nav {
  margin: 0;
  border-bottom: 1px solid #e9e9e9;
}

.c-tabs__nav-item {
  display: inline-block;
  margin: 0;
}

.c-tabs__nav-link {
  color: #666;
  display: block;
  margin-right: 1em;
  text-decoration: none;
}

@media (min-width: 768px) {
  .c-tabs__nav-link {
    margin-right: 2em;
  }
}

@media (min-width: 1024px) {
  .c-tabs__nav-link {
    margin-right: 3em;
  }
}

.c-tabs__nav-link:after {
  background: #006940;
  content: '';
  display: block;
  height: 2px;
  margin-top: 0.5em;
  transition: all 200ms ease-in-out;
  width: 0;
}

.c-tabs__nav-link.is-active, .c-tabs__nav-link:hover {
  color: #006940;
}

.c-tabs__nav-link.is-active:after, .c-tabs__nav-link:hover:after {
  width: 100%;
}

.c-tabs__tab {
  padding-top: 2em;
}

.c-tease-post {
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 1.66667rem;
  padding-bottom: 1.66667rem;
}

.c-tease-post:last-child {
  border: none;
}

.c-tease-post__date {
  font-size: 0.77778rem;
  color: #999;
  margin-bottom: 0.5em;
}

.c-tease-post__title {
  font-size: 1.16667rem;
  margin-bottom: 0.15em;
}

.c-tease-post__title a {
  color: #006940;
  text-decoration: none;
}

.c-tease-post__title a:hover {
  text-decoration: underline;
}

.c-tease-post__desc {
  font-size: 0.88889rem;
  color: #666;
}

.c-testimonial {
  color: #3b8900;
  margin: 1em 0;
}

@media (min-width: 768px) {
  .c-testimonial {
    margin: 1em 0 2em 0;
  }
}

@media (min-width: 1024px) {
  .c-testimonial {
    margin: 2em 0 3em 0;
  }
}

.c-testimonial:first-child:not(.c-testimonial--alt) {
  margin-top: -1em;
}

.c-testimonial--alt {
  color: #666;
}

@media (min-width: 1024px) {
  .c-testimonial--alt {
    margin: 1em 0 3em 0;
  }
}

.c-testimonial__text {
  font-size: 1.16667rem;
  position: relative;
  padding-left: 2em;
}

.c-testimonial__text p:first-child:before {
  font-size: 3.55556rem;
  font-weight: bold;
  left: 0;
  line-height: 1;
  position: absolute;
  top: 0.1em;
}

.c-testimonial__author {
  font-size: 0.88889rem;
  font-style: normal;
  font-weight: bold;
  margin-top: 1em;
  text-align: right;
}

.c-video {
  height: 0;
  overflow: hidden;
  margin-bottom: 1em;
  padding-bottom: 56.25%;
  padding-top: 30px;
  position: relative;
}

.c-video iframe,
.c-video object,
.c-video embed {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

/* ==========================================================================
   #ALIGN
   ========================================================================== */
.u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

.u-clear {
  clear: both;
}

.u-text-center {
  text-align: center;
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

/* ==========================================================================
   #CLEARFIX
   ========================================================================== */
/**
 * Attach our clearfix mixin to a utility class.
 */
.u-clearfix:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

/* ==========================================================================
   #COLOR
   ========================================================================== */
.u-fill {
  background: #e9e9e9;
}

/* ==========================================================================
   #HIDE
   ========================================================================== */
/**
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.u-hidden-visually {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

/**
 * Hide visually and from screen readers.
 */
.u-hidden {
  display: none !important;
}

.u-hidden\@small {
  display: none;
}

@media (min-width: 768px) {
  .u-hidden\@small {
    display: block;
  }
}

/* ==========================================================================
   #SPACING
   @todo generate more generic classes and sizes
   ========================================================================== */
.u-margin {
  margin: 1em;
}

.u-margin-top {
  margin-top: 1em;
}

.u-margin-top2 {
  margin-top: 2em;
}

.u-margin-right {
  margin-right: 1em;
}

.u-margin-bottom {
  margin-bottom: 1em;
}

.u-margin-left {
  margin-left: 1em;
}

.u-padding {
  padding: 1em;
}

.u-padding-top {
  padding-top: 1em;
}

.u-padding-right {
  padding-right: 1em;
}

.u-padding-bottom {
  padding-bottom: 1em;
}

.u-padding-left {
  padding-left: 1em;
}

/*!
 * baguetteBox.js
 * @author  feimosi
 * @version %%INJECT_VERSION%%
 * @url https://github.com/feimosi/baguetteBox.js
 */
#baguetteBox-overlay {
  display: none;
  opacity: 0;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000000;
  background-color: #222;
  background-color: rgba(0, 0, 0, 0.8);
  transition: opacity .5s ease;
}

#baguetteBox-overlay.visible {
  opacity: 1;
}

#baguetteBox-overlay .full-image {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
}

#baguetteBox-overlay .full-image figure {
  display: inline;
  margin: 0;
  height: 100%;
}

#baguetteBox-overlay .full-image img {
  display: inline-block;
  width: auto;
  height: auto;
  max-height: 100%;
  max-width: 100%;
  vertical-align: middle;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}

#baguetteBox-overlay .full-image figcaption {
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  line-height: 1.8;
  white-space: normal;
  color: #ccc;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.6);
  font-family: sans-serif;
}

#baguetteBox-overlay .full-image:before {
  content: "";
  display: inline-block;
  height: 50%;
  width: 1px;
  margin-right: -1px;
}

#baguetteBox-slider {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  white-space: nowrap;
  transition: left .4s ease, transform .4s ease;
}

#baguetteBox-slider.bounce-from-right {
  animation: bounceFromRight .4s ease-out;
}

#baguetteBox-slider.bounce-from-left {
  animation: bounceFromLeft .4s ease-out;
}

@keyframes bounceFromRight {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -30px;
  }
  100% {
    margin-left: 0;
  }
}

@keyframes bounceFromLeft {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 30px;
  }
  100% {
    margin-left: 0;
  }
}

.baguetteBox-button#next-button, .baguetteBox-button#previous-button {
  top: 50%;
  top: calc(50% - 30px);
  width: 44px;
  height: 60px;
}

.baguetteBox-button {
  position: absolute;
  cursor: pointer;
  outline: none;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 15%;
  background-color: #323232;
  background-color: rgba(50, 50, 50, 0.5);
  color: #ddd;
  font: 1.6em sans-serif;
  transition: background-color .4s ease;
}

.baguetteBox-button:focus, .baguetteBox-button:hover {
  background-color: rgba(50, 50, 50, 0.9);
}

.baguetteBox-button#next-button {
  right: 2%;
}

.baguetteBox-button#previous-button {
  left: 2%;
}

.baguetteBox-button#close-button {
  top: 20px;
  right: 2%;
  right: calc(2% + 6px);
  width: 30px;
  height: 30px;
}

.baguetteBox-button svg {
  position: absolute;
  left: 0;
  top: 0;
}

/*
    Preloader
    Borrowed from http://tobiasahlin.com/spinkit/
*/
.baguetteBox-spinner {
  width: 40px;
  height: 40px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -20px;
}

.baguetteBox-double-bounce1,
.baguetteBox-double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #fff;
  opacity: .6;
  position: absolute;
  top: 0;
  left: 0;
  animation: bounce 2s infinite ease-in-out;
}

.baguetteBox-double-bounce2 {
  animation-delay: -1s;
}

@keyframes bounce {
  0%, 100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
