/* Address */

.c-address {
  margin-bottom: 2em;

  a {
    color: $color-white;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.c-address__title {
  font-size: rem(21);
  margin-bottom: 0.5em;
}

.c-address__subtitle {
  color: $color-white;
  font-size: rem(16);
  margin-bottom: 0.5em;
}

.c-address__text {
  font-size: rem(14);
}

.c-address__list {
  font-size: rem(14);
  list-style: none;
  margin: 1.3em 0 0 0;
}

.c-address__list-item {
  margin: 0;

  strong {
    display: inline-block;
    width: 4em;
  }
}

.c-address--alt {
  margin: 0;

  a {
    color: $color-link;
  }

  .c-address__subtitle {
    color: $color-text;
    font-size: rem(18);
  }

  .c-address__text,
  .c-address__list {
    font-size: rem(18);
    margin-top: 0;
  }
}
