.c-brand {
  background: $color-white;
  box-shadow: $global-box-shadow;
  padding: em(30);
  margin-bottom: em(40);

  @include bp-medium {
    padding: em(40);
    margin-bottom: em(50);
  }

  @include bp-large {
    padding: em(55) em(70);
    margin-bottom: em(60);
  }
}

.c-brand-desc {
  margin-bottom: 3em;

  @include bp-medium {
    border-right: 1px solid $color-border;
    margin-bottom: 0;
    padding-right: 2em;
  }
}

.c-brand__title {
  font-size: rem(36);
  margin-bottom: 0.5em;
}

.c-brand__description {
  font-size: rem(21);
  color: $color-emp-text;
}

.c-brand__text {
  & p:last-child {
    margin-bottom: 0;
  }
}

.c-brand__subtitle {
  color: $color-emp-text;
  font-size: rem(18);
  margin: 2em 0 0 0;
}

.c-brand__url {
  font-weight: bold;
  margin: 2em 0 0 0;
}
