
.js-tab.is-hidden {
  display: none;
}

.c-tabs {
  margin-top: 3em;
}

.c-tabs__nav {
  margin: 0;
  border-bottom: 1px solid $color-border;
}

.c-tabs__nav-item {
  display: inline-block;
  margin: 0;
}

.c-tabs__nav-link {
  color: $color-alt;
  display: block;
  margin-right: 1em;
  text-decoration: none;

  @include bp-medium {
    margin-right: 2em;
  }

  @include bp-large {
    margin-right: 3em;
  }

  &:after {
    background: $color-heading;
    content: '';
    display: block;
    height: 2px;
    margin-top: 0.5em;
    transition: $global-transition;
    width: 0;
  }

  &.is-active,
  &:hover {
    color: $color-heading;

    &:after {
      width: 100%;
    }
  }
}

.c-tabs__tab {
  padding-top: 2em;
}

