
.c-page-intro {
  background: $color-white;
  box-shadow: $global-box-shadow;
  padding: em(30);
  margin-bottom: em(40);

  @include bp-small {
    padding: em(30) em(40);
    margin-bottom: em(40);
  }

  @include bp-medium {
    padding: em(35) em(50);
    margin-bottom: em(40);
  }

  @include bp-large {
    padding: em(45) em(60);
    margin-bottom: em(50);
  }

  @include bp-full {
    padding: em(55) em(70);
    margin-bottom: em(60);
  }
}

.c-page-intro__title {
  font-size: rem(36);
  margin-bottom: 0.5em;
}

.c-page-intro__text {
  font-size: rem(21);
  color: $color-emp-text;

  & p:last-child {
    margin-bottom: 0;
  }
}
