/* ==========================================================================
   #MAIN NAV
   ========================================================================== */

.c-main-nav {
  border-top: 1px solid $color-border;
  margin: 2.5em 0 0 0;

  @include bp-medium {
    border: 0;
    display: flex;
    left: em(225);
    margin: 0;
    position: absolute;
    top: em(65);
  }

  @include bp-large {
    left: em(250);
  }

  @include bp-xlarge {
    left: em(300);
  }

  @include bp-full {
    left: em(350);
  }
}

.c-main-nav__item {
  list-style: none;
  margin-bottom: 0;
  margin-top: 0;
  margin-left: 0;
  padding: 0;

  @include bp-medium {
    margin-right: em(25);
  }

  @include bp-large {
    margin-right: em(35);
  }

  @include bp-xlarge {
    margin-right: em(45);
  }

  @include bp-full {
    margin-right: em(55);
  }
}

.c-main-nav__link {
  color: $color-dark-grey;
  border-bottom: 1px solid $color-border;
  display: block;
  text-decoration: none;
  padding: 0.75em 0;

  @include bp-medium {
    border-bottom: 0;
    padding: 0.5em 0;

    &:after {
      background: $color-heading;
      content: '';
      display: block;
      height: 2px;
      margin-top: 0.25em;
      transition: $global-transition;
      width: 0;
    }

    &:hover {
      color: $color-heading;

      &:after {
        width: 100%;
      }
    }
  }
}

.current-menu-item .c-main-nav__link {
  color: $color-heading;
  border-bottom-color: $color-heading;
  font-weight: bold;

  &:after {
    width: 100%;
  }
}
