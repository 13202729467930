/* Secondary Lang Navigation */

.c-sec-lang-nav {
  font-size: rem(14);
  margin: 0 0 2em 0;
}

.c-sec-lang-nav__item {
  display: inline-block;
  line-height: 1;
  list-style-type: none;

  &:first-child {
    border-right: 1px solid $color-white;
    margin-right: 0.25em;
    padding-right: 0.5em;
  }

  &.current-lang {
    font-weight: bold;
  }
}

.c-sec-lang-nav__link {
  color: $color-white;
  text-decoration: none;

  &:hover {
    color: $color-white;
    text-decoration: underline;
  }
}

