
.c-testimonial {
  color: $color-testimonial;
  margin: 1em 0;

  @include bp-medium {
    margin: 1em 0 2em 0;
  }

  @include bp-large {
    margin: 2em 0 3em 0;
  }

  &:first-child:not(.c-testimonial--alt) {
    margin-top: -1em;
  }
}

.c-testimonial--alt {
  color: $color-alt;

  @include bp-large {
    margin: 1em 0 3em 0;
  }
}

.c-testimonial__text {
  font-size: rem(21);
  position: relative;
  padding-left: 2em;


  p:first-child:before {
    font-size: rem(64);
    font-weight: bold;
    left: 0;
    line-height: 1;
    position: absolute;
    top: 0.1em;
  }
}

.c-testimonial__author {
  font-size: rem(16);
  font-style: normal;
  font-weight: bold;
  margin-top: 1em;
  text-align: right;
}
