
.c-image {
  margin-bottom: 0;
}

.c-image__placeholder {
  background: $color-fill;
  box-shadow: $global-box-shadow;
  height: 0;
  overflow: hidden;
  padding-top: 66.6667%;
  position: relative;
  width: 100%;

  img,
  iframe {
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.c-image__desc {
  color: $color-alt;
  font-size: rem(16);
  font-style: italic;
  margin-top: 1em;
  text-align: center;
}
