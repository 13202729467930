/* Sec Navigation */

.c-sec-nav {
  margin: 0 0 2em 0;
}

.c-sec-nav__item {
  font-size: rem(14);
  list-style-type: none;

  &:first-child {
    font-size: rem(16);
    font-weight: bold;
  }
}

.c-sec-nav__link {
  color: $color-white;
  text-decoration: none;

  &:hover {
    color: $color-white;
    text-decoration: underline;
  }
}
